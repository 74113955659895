var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSize, lineHeight, themeGet } from 'styled-system';
import { getThemeColor } from '../theme/helper';
import { focusOutlineCSSWithOffset } from '../theme/styles';
import { dispatchTrackingEventGA4 } from '../tracking/dispatchTrackingEventGA4';
import { Button } from './Button';
export var linkStyles = function (props) {
    var textDecoration = props.textDecoration || 'none';
    return (props.styled && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      color: ", ";\n      text-decoration: ", ";\n      cursor: pointer;\n\n      &:hover,\n      &:focus {\n        color: ", ";\n\n        text-decoration: underline;\n        text-decoration-color: ", ";\n      }\n\n      &:active {\n        color: ", ";\n        text-decoration: none;\n      }\n    "], ["\n      color: ", ";\n      text-decoration: ", ";\n      cursor: pointer;\n\n      &:hover,\n      &:focus {\n        color: ", ";\n\n        text-decoration: underline;\n        text-decoration-color: ", ";\n      }\n\n      &:active {\n        color: ", ";\n        text-decoration: none;\n      }\n    "])), getThemeColor('sys.neutral.text.default')(props), textDecoration, getThemeColor('sys.neutral.text.hover')(props), getThemeColor('sys.neutral.text.hover')(props), getThemeColor('sys.neutral.text.active')(props)));
};
var SecondaryLinkButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  /* Shop-Reboot :'( */\n  text-decoration: none !important;\n\n  ", ";\n  ", ";\n"], ["\n  /* Shop-Reboot :'( */\n  text-decoration: none !important;\n\n  ", ";\n  ", ";\n"])), lineHeight, fontSize);
SecondaryLinkButton.defaultProps = __assign(__assign({}, Button.defaultProps), { as: 'a', kind: 'secondary' });
export { SecondaryLinkButton };
var StyledLink = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: inherit;\n  text-decoration: none;\n\n  ", ";\n\n  ", ";\n  ", ";\n\n  &:hover {\n    color: ", ";\n    border-color: ", ";\n  }\n\n  border-radius: ", ";,\n  ", ";\n"], ["\n  color: inherit;\n  text-decoration: none;\n\n  ", ";\n\n  ", ";\n  ", ";\n\n  &:hover {\n    color: ", ";\n    border-color: ", ";\n  }\n\n  border-radius: ", ";,\n  ", ";\n"])), linkStyles, fontSize, lineHeight, themeGet('colors.sys.neutral.text.hover'), themeGet('colors.sys.neutral.text.hover'), themeGet('radii.medium'), focusOutlineCSSWithOffset);
export var Link = forwardRef(function (_a, ref) {
    var tracking = _a.tracking, onClick = _a.onClick, _b = _a.styled, styledProp = _b === void 0 ? true : _b, restProps = __rest(_a, ["tracking", "onClick", "styled"]);
    var handleClick = function (evt) {
        onClick === null || onClick === void 0 ? void 0 : onClick(evt);
        if (tracking)
            dispatchTrackingEventGA4(__assign(__assign(__assign({ event: tracking.eventName, element: 'link' }, (tracking.text && { link_text: tracking.text })), (tracking.clickedText && { clicked_text: tracking.clickedText })), (tracking.placement && { placement: tracking.placement })));
    };
    return React.createElement(StyledLink, __assign({}, restProps, { styled: styledProp, ref: ref, onClick: handleClick }));
});
Link.displayName = 'Link';
export var LinkWithIcon = styled(Link)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &:hover svg,\n  &:focus svg {\n    fill: ", ";\n  }\n  &:active svg {\n    fill: ", ";\n  }\n"], ["\n  &:hover svg,\n  &:focus svg {\n    fill: ", ";\n  }\n  &:active svg {\n    fill: ", ";\n  }\n"])), themeGet('colors.sys.primary.background.default'), themeGet('colors.sys.primary.background.active'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
