var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ReactPlaceholder from 'react-placeholder';
import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { width } from 'styled-system';
// Those are styles copied from 'react-placeholder/lib/reactPlaceholder.css'
// We inject them directly into the wrapper div.
// Importing .css file causes performance issue with next.js, as it's being
// extracted to separate stylesheet (which delays initial load and rendering).
// In Corgi it's handled by custom Webpack config (see "with-lazy-styles.js"),
// but that would be an overkill to copy this to next.js webpack configuration.
var reactPlaceholderStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .show-loading-animation.rect-shape,\n  .show-loading-animation.round-shape,\n  .show-loading-animation.text-row,\n  .show-loading-animation .rect-shape,\n  .show-loading-animation .round-shape,\n  .show-loading-animation .text-row {\n    animation: react-placeholder-pulse 1.5s infinite;\n  }\n\n  @keyframes react-placeholder-pulse {\n    0% {\n      opacity: 0.6;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0.6;\n    }\n  }\n"], ["\n  .show-loading-animation.rect-shape,\n  .show-loading-animation.round-shape,\n  .show-loading-animation.text-row,\n  .show-loading-animation .rect-shape,\n  .show-loading-animation .round-shape,\n  .show-loading-animation .text-row {\n    animation: react-placeholder-pulse 1.5s infinite;\n  }\n\n  @keyframes react-placeholder-pulse {\n    0% {\n      opacity: 0.6;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0.6;\n    }\n  }\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
Container.defaultProps = {
    width: 1,
};
var SkeletonLoader = function (_a) {
    var children = _a.children, placeholder = _a.placeholder, ready = _a.ready;
    return (jsx(Container, { css: reactPlaceholderStyles },
        jsx(ReactPlaceholder, { ready: ready, customPlaceholder: placeholder }, children)));
};
export default SkeletonLoader;
var templateObject_1, templateObject_2;
