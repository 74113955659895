var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, boxShadow, color, flexDirection, flexWrap, justifyContent, maxWidth, minWidth, order, position, space, display as styledDisplay, width as styledWidth, top, zIndex, } from 'styled-system';
import { flexGrow, overflowX, } from '../theme/system-utilities';
import { Flex } from './Flex';
var GridWrapper = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), backgroundColor, minWidth, maxWidth, boxShadow);
GridWrapper.defaultProps = {
    width: 1,
    backgroundColor: 'sys.neutral.background.default',
    minWidth: 320,
    maxWidth: '100%',
};
export var FullWidthBoxShadow;
(function (FullWidthBoxShadow) {
    FullWidthBoxShadow["BOTTOM"] = "bottom";
})(FullWidthBoxShadow || (FullWidthBoxShadow = {}));
export var FlexJustifyContent;
(function (FlexJustifyContent) {
    FlexJustifyContent["CENTER"] = "center";
    FlexJustifyContent["SPACE_BETWEEN"] = "space-between";
})(FlexJustifyContent || (FlexJustifyContent = {}));
export var FlexAlignItems;
(function (FlexAlignItems) {
    FlexAlignItems["CENTER"] = "center";
    FlexAlignItems["START"] = "flex-start";
})(FlexAlignItems || (FlexAlignItems = {}));
export var FlexWrap;
(function (FlexWrap) {
    FlexWrap["WRAP"] = "wrap";
    FlexWrap["NO_WRAP"] = "nowrap";
})(FlexWrap || (FlexWrap = {}));
// S (320-631):
// - total area (content area + offset): 320px
// - content area: 288px
// - offset: 16px
// - gutter: 16px
// - column: 60px
// - 4 columns
// M (632-983):
// - total area (content area + offset): 632px
// - content area: 584px
// - offset: 24px
// - gutter: 24px
// - column: 52px
// - 8 columns
// L (984-1391):
// - total area (content area + offset): 984px
// - content area: 888px
// - offset: 48px
// - gutter: 24px
// - column: 52px
// - 12 columns
// XL (1392-1600):
// - total area (content area + offset): 1392px
// - content area: 1264px
// - offset: 64px
// - gutter: 32px
// - column: 76px
// - 12 columns
var GridContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  box-sizing: border-box;\n\n  display: flex;\n  ", ";\n\n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n"], ["\n  width: 100%;\n  box-sizing: border-box;\n\n  display: flex;\n  ", ";\n\n  ", ";\n  ", ";\n\n  ", ";\n  ", ";\n"])), flexWrap, alignItems, justifyContent, maxWidth, space);
GridContainer.defaultProps = {
    mx: 'auto',
    px: [2, '12px', '36px', 6],
    maxWidth: 1600,
    flexWrap: 'wrap',
};
export var Grid = React.forwardRef(function (_a, ref) {
    var bg = _a.bg, minWidthValue = _a.minWidth, children = _a.children, legacyShopRebootMaxWidth = _a.legacyShopRebootMaxWidth, fullWidthBoxShadow = _a.fullWidthBoxShadow, as = _a.as, props = __rest(_a, ["bg", "minWidth", "children", "legacyShopRebootMaxWidth", "fullWidthBoxShadow", "as"]);
    return (React.createElement(GridWrapper, { ref: ref, as: as, minWidth: minWidthValue, backgroundColor: bg, boxShadow: fullWidthBoxShadow, "data-testid": "grid-wrapper" },
        React.createElement(GridContainer, __assign({}, (legacyShopRebootMaxWidth && { maxWidth: [null, null, '1064px', '1096px'] }), props), children)));
});
Grid.displayName = 'Grid';
Grid.defaultProps = {
    legacyShopRebootMaxWidth: false,
};
var GridItemContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  flex: none;\n  box-sizing: border-box;\n\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  display: block;\n  flex: none;\n  box-sizing: border-box;\n\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), space, styledWidth, order, maxWidth, flexDirection, styledDisplay, overflowX, position, flexGrow, alignItems, top, zIndex, backgroundColor, boxShadow, justifyContent);
GridItemContainer.defaultProps = {
    width: 1,
    px: [2, '12px', null, 3],
};
export var GridItem = React.forwardRef(function (_a, ref) {
    var w = _a.w, width = _a.width, props = __rest(_a, ["w", "width"]);
    return (React.createElement(GridItemContainer, __assign({ width: width || w }, props, { ref: ref })));
});
GridItem.displayName = 'GridItem';
GridItem.defaultProps = {
    w: 1,
};
var Border = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 1px;\n  margin-top: -1px;\n\n  ", ";\n  ", ";\n"], ["\n  width: 100%;\n  height: 1px;\n  margin-top: -1px;\n\n  ", ";\n  ", ";\n"])), styledDisplay, color);
Border.defaultProps = {
    backgroundColor: 'sys.neutral.background.active',
    display: 'block',
};
/**
 * Creates a zero-height border in the grid where the border width is
 * matching the grid content width
 */
export var GridItemBorder = function (_a) {
    var w = _a.w, width = _a.width, display = _a.display;
    return (React.createElement(GridItem, { w: w, width: width },
        React.createElement(Border, { display: display })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
